//
// Custom CSS
//


// page
body {
  > section,
  > footer {
    padding: 4rem 0;
  }

  background-color: #f8f8f8;
}

a {
  color: #4b8f27;
  text-decoration: none;

  &:hover {
    color: #4b8f27;
    text-decoration: underline;
  }
}

.alert a {
  color: inherit;
  text-decoration: underline;

  &:hover {
    color: inherit;
  }
}


.header + section.documentation-hero {

  padding-top: 4.5rem;
  padding-bottom: 2.5rem;

  .row + .row {
    margin-top: 0;
  }
}

.main-section {
  .card.stacked {
    min-height: 290px;
  }

  a > .card,
  a:active > .card,
  a:hover > .card,
  a:focus > .card,
  a.no-style {
    color: $body-color;
    text-decoration: none !important;
  }
}

a.no-style {
  color: $body-color;
  text-decoration: none !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.85);
  text-transform: none;
  border-top: none;
  font-size: 0.95rem;

  &:hover {
    color: rgba(255, 255, 255, 1);
  }
}

article {
  h1, h2, h3, h4, h5 {
    margin-bottom: 2rem;
    margin-top: 4rem;
  }

  p, img {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }

  .alert {
    h1, h2, h3, h4, h5, p, img {
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }
}


.nav-toc-page.nav-toc-page-right .nav-link::before,
.nav-toc-page.nav-toc-page-right .nav-link::after {
  content: "";
}

.nav-toc-page.nav-toc-page-right {
  margin-top: 0;
  border: none;
  background: transparent;
  padding: 0;
}

.nav-toc-page.nav-toc-page-right:before {
  content: "";
}

.nav-toc-page .nav-link.active {
  background: #e5e5e5;
  color: #000;
}

.nav-toc-page .nav-link {
  margin: 1px 0;
  padding: 13px;
}

.nav-toc-page .nav-toc-page .nav-link {
  font-size: 0.8rem;
  padding: 9px;
}

article .shadowed img {
  box-shadow: 0px 10px 15px 0 rgba($black, 0.1);
}

.header-sticky {
  position: absolute;
}

ul.ul-2-cols {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  display: block;
}

ul.ul-3-cols {
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
  display: block;
}

ul.ul-4-cols {
  columns: 4;
  -webkit-columns: 4;
  -moz-columns: 4;
  display: block;
}


.search-results {
  article {
    border-bottom: solid 1px $gray-300;
    margin-bottom: 10px;

    h3 {
      margin: 0 0 0.5rem 0;
      font-size: 18px;
    }

    p {
      margin: 0 0 0.5rem 0;
      font-size: 14px;
    }
  }
}

.btn-tag {
  color: #111111;
  background-color: #e5e5e5;
  border-color: #f8f8f8;
  margin: 5px 4px;
  display: inline-block;

  &:hover {
    color: #111111;
    background-color: #e5e5e5;
    border-color: #dfdfdf;
  }
}

.navbar .form-inline {
  input {
    padding: 3px 15px;
    height: 42px;
    line-height: 42px;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }
}

.search-results-overlay {
  position: absolute;
  top: 58px;
  width: 60%;
  overflow: auto;
  max-height: 700px;

  box-shadow: 0px 10px 15px 0 rgba($black, 0.1);

  article, > p {
    background: white;
    display: block;
    padding: 5px 20px;
    font-size: 11px;

    h3 {
      margin: 0 0 0.5rem 0;
      font-size: 18px;
    }

    p {
      margin: 0 0 0.5rem 0;
      font-size: 13px;
    }

    &:first-child {
      padding-top: 15px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-child {
      padding-bottom: 15px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    border-bottom: $gray-300;
  }
}


table {
  width: 100%;
  margin-bottom: 1rem;
  color: #555555;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  border-radius: 0.25rem;
  border: 1px solid #e5e5e5;
  background: white;
  font-size: 0.85rem;
}

table th, table td {
  border-top: 0;
  padding: 1rem 1rem;
}

table th, table td {
  padding: 1rem;
  vertical-align: top;
  border-top: 1px solid #e5e5e5;
}

table thead th {
  border-bottom: 0;
  border-top: 0;
  vertical-align: bottom;
  border-bottom: 0
}

table th {
  font-weight: 400;
  color: #999999;
}

table thead th {
  background-color: $green;
  color: white;
  font-weight: bold;
}